import { css, mq } from "@creditas/stylitas"
import { spacing } from "./spacing"

const types = (type, margin) =>
  ({
    m: css`
      ${mq({ margin })}
    `,
    my: css`
      ${mq({ marginTop: margin, marginBottom: margin })}
    `,
    mx: css`
      ${mq({ marginLeft: margin, marginRight: margin })}
    `,
    mt: css`
      ${mq({ marginTop: margin })}
    `,
    mb: css`
      ${mq({ marginBottom: margin })}
    `,
    mr: css`
      ${mq({ marginRight: margin })}
    `,
    ml: css`
      ${mq({ marginLeft: margin })}
    `,
  }[type])

export const margin = (type, size) => {
  const sizes = Array.isArray(size) ? size : [size]
  const margin = sizes.map(value => spacing(value))

  return types(type, margin)
}

export const marginHelper = ({ m, my, mx, mt, mb, mr, ml }) => css`
  ${typeof m !== "undefined" && margin("m", m)};
  ${typeof my !== "undefined" && margin("my", my)};
  ${typeof mx !== "undefined" && margin("mx", mx)};
  ${typeof mt !== "undefined" && margin("mt", mt)};
  ${typeof mb !== "undefined" && margin("mb", mb)};
  ${typeof mr !== "undefined" && margin("mr", mr)};
  ${typeof ml !== "undefined" && margin("ml", ml)};
`
