import { css, mq, utilities } from "@creditas/stylitas"

const responsiveTypographyColor = (theme) => mq({
  color: [
    utilities.palette.color(theme, "neutral07"),
    utilities.palette.color(theme, "neutral07"),
    utilities.palette.color(theme, "neutral07"),
    utilities.palette.color(theme, "neutral01")
  ]
})

export const customActionLink = ({ theme }) => css`
  min-width: 270px;
  align-items: center;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  ${utilities.typography.support(theme)}};
  ${responsiveTypographyColor(theme)};
  &:hover {
    ${responsiveTypographyColor(theme)};
  }
`

export const customTypography = ({ variant, theme }) => css`
  ${responsiveTypographyColor(theme)};
  ${variant === "h2" && utilities.typography.h4(theme)};
`

export const backgroundContainer = ({ src }) => css`
  min-width: 100%;
  background-size: cover;
  background-color: transparent;
  ${mq({ backgroundImage: ['none', 'none', `none`, `url(${src})`] })}
`
