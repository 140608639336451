import React from "react"
import { Row, Col } from "react-grid-system"
import { styled } from "@creditas/stylitas"
import { graphql, useStaticQuery } from "gatsby"

import { Typography, Button, ActionLink, Container } from "components"
import { customActionLink, customTypography, backgroundContainer } from "./hero.style"

const CustomActionLink = styled(ActionLink)`
  ${customActionLink}
`

const CustomTypography = styled(Typography)`
  ${customTypography}
`

const BackgroundContainer = styled(Container)`
  ${backgroundContainer}
`

export const Hero = () => {
  const { desktopBackground } = useStaticQuery(graphql`
    query {
      desktopBackground: file(relativePath: { eq: "home-hero-background.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <BackgroundContainer component="section" src={desktopBackground.childImageSharp.fluid.src}>
      <Container component="section" pt={9} pb={14}>
        <Row>
          <Col lg={7}>
            <CustomTypography variant="h2">
              Encontre aqui as melhores ofertas
            </CustomTypography>
            <CustomTypography variant="h1" mt={1}>
              Compre seu carro sem sair de casa
            </CustomTypography>
            <CustomTypography variant="support" mt={2}>
              Tudo online e o carro entregue na sua casa.
            </CustomTypography>
            <Button type="button" display="block" mt={8}>
              Quero Comprar
            </Button>
            <CustomActionLink mt={2}>Quero Vender</CustomActionLink>
          </Col>
        </Row>
      </Container>
    </BackgroundContainer>
  )
}
