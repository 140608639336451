import React from "react"
import { styled } from "@creditas/stylitas"
import { Card, CardMedia, CardContent } from "@creditas/card"

import { Container, Typography, Row, Col } from "components"
import { customTypography } from "./catalog.style"

const CustomTypography = styled(Typography)`
  ${customTypography}
`

export const Catalog = () => (
  <Container component="section" pt={9}>
    <Row>
      <Col>
        <Typography variant="support">
          Todos nossos carros são inspecionados rigorosamente pelos nossos
          especialistas.
        </Typography>
        <CustomTypography variant="h2">
          Nossas melhores ofertas selecionadas para você:
        </CustomTypography>
      </Col>
    </Row>
    <Row mt={7}>
      {[...Array(9).keys()].map(key => (
        <Col xs={12} lg={3} mb={3} key={key}>
          <Card>
            <CardMedia
              component="img"
              image="https://via.placeholder.com/288x197"
              title="Nome Marca Nome modelo"
            />
            <CardContent>
              <Typography variant="h6">Nome Marca Nome modelo</Typography>
              <Typography variant="support" mt={1}>
                1.4 LX 16C Flex 4P Manual 0000/0000
              </Typography>
              <Typography variant="h5" color="#11bb77" mt={4}>
                R$ 000.000,00
              </Typography>
            </CardContent>
          </Card>
        </Col>
      ))}
    </Row>
  </Container>
)
