import { css, mq } from "@creditas/stylitas"
import { spacing } from "./spacing"

const types = (type, padding) =>
  ({
    p: css`
      ${mq({ padding })}
    `,
    py: css`
      ${mq({ paddingTop: padding, paddingBottom: padding })}
    `,
    px: css`
      ${mq({ paddingLeft: padding, paddingRight: padding })}
    `,
    pt: css`
      ${mq({ paddingTop: padding })}
    `,
    pb: css`
      ${mq({ paddingBottom: padding })}
    `,
    pr: css`
      ${mq({ paddingRight: padding })}
    `,
    pl: css`
      ${mq({ paddingLeft: padding })}
    `,
  }[type])

export const padding = (type, size) => {
  const sizes = Array.isArray(size) ? size : [size]
  const padding = sizes.map(value => spacing(value))

  return types(type, padding)
}

export const paddingHelper = ({ p, py, px, pt, pb, pr, pl }) => css`
  ${typeof p !== "undefined" && padding("p", p)};
  ${typeof py !== "undefined" && padding("py", py)};
  ${typeof px !== "undefined" && padding("px", px)};
  ${typeof pt !== "undefined" && padding("pt", pt)};
  ${typeof pb !== "undefined" && padding("pb", pb)};
  ${typeof pr !== "undefined" && padding("pr", pr)};
  ${typeof pl !== "undefined" && padding("pl", pl)};
`
