import React from "react"
import { string } from "prop-types"
import { styled } from "@creditas/stylitas"
import { Button as Root } from "@creditas/button"

import { marginHelper } from "utilities"
import { customDisplay } from "./button.style"

const ButtonStyled = styled(Root)`
  ${marginHelper}
  ${customDisplay}
`

const propTypes = {
  display: string,
}

const defaultProps = {
  display: "inline-block",
}

const Button = ({ children, ...props }) => (
  <ButtonStyled {...props}>{children}</ButtonStyled>
)

Button.propTypes = propTypes
Button.defaultProps = defaultProps

export { Button }
