import React from "react"

import { Meta } from "components"
import { Hero } from "../modules/home/components/Hero"
import { Catalog } from "../modules/home/components/Catalog"

const HomePage = () => (
  <>
    <Meta title="Carditas" />
    <Hero />
    <Catalog />
  </>
)

export default HomePage
